<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <HistoryChart msg="Welcome to Your Vue.js App"/>
  <!-- <TestChart2 msg="TestChart222"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import HistoryChart from './components/HistoryChart.vue'
// import TestChart2 from './components/TestChart2.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    HistoryChart,
    // TestChart2
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
:root {
        --background: #131722;
        --background-dark: #000;
        --font: #eeeeee;
        --brand: #10d876;
        --green: #10d876;
        --red: #ff0000;
        --green-bright: #00ff00;
        --red-bright: #ff0000;
        --primary-button: #10d876;
        --secondary-button: #6c757d;
        --even: #1c1c1c;
        --odd: #202020;
        --border: #2d2d2d;
    }
</style>
