import { createRouter, createWebHistory } from 'vue-router';
import HistoryChart from './components/HistoryChart.vue';

const routes = [
    // { path: '/', name: 'Home', component: Home },
    // { path: '/edit', name: 'Edit', component: Edit },
    { path: '/', name: 'History', component: HistoryChart },
  ]
  
const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
})

export default router;