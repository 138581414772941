<template>
  <div id="container" class="container">
    <div class="columns is-desktop row">
      <div id="title" class="column hist-chart col-lg-8">
          <span>Coin</span>
            <div class="select is-small">
              <select v-model="selectedCoin" v-on:change="setAPIdata">
                <!-- <option disabled value="">Coin</option> -->
                <option v-for="coin in optionCoins" v-bind:value="coin.name" v-bind:key="coin.id">
                  {{ coin.name }}
                </option>
              </select>
            </div>
          <span>Currency</span>
            <div class="select is-small">
              <select v-model="selectedCurrency" v-on:change="setAPIdata">
                <!-- <option disabled value="">Currency</option> -->
                <option v-for="curr in optionCurrency" v-bind:value="curr.name" v-bind:key="curr.id">
                  {{ curr.name }}
                </option>
              </select>
            </div>
        <highcharts :constructor-type="'stockChart'" :options="chartOptions" />
      </div>
      <div class="column report-summary col-lg-4">
        <div id="report" >
          <table id="dop" class="table is-bordered">
            <tbody>
              <th colspan="3" class="has-text-centered">Data of the Period</th>
              <tr>
                <th id="rep_th" scope="row">From:</th>
                <!-- <td id="fromprice" name="fromprice"></td> -->
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ range_data.fromprice }}</td>
                <!-- <td name="date1"></td> -->
                <td>{{ showdate(range_data.fromtime) }}</td>
              </tr>
              <tr>
                <th scope="row">To:</th>
                <!-- <td name="toprice"></td> -->
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ range_data.toprice }}</td>
                <!-- <td name="date2"></td> -->
                <td>{{ showdate(range_data.totime) }}</td>
              </tr>
              <tr>
                <th scope="row">Period:</th>
                <!-- <td name="pricechange"></td> -->
                <td>{{ range_data.pricechange }} %</td>
                <td name="period"></td>
              </tr>
              <tr>
                <!-- <th scope="row">Volatility: </th> -->
                <th scope="row">Volatility:</th>
                <td>{{ (range_data.volatility).toFixed(3) }}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Highest:</th>
                <!-- <td name="highest"></td> -->
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ range_data.highprice }}</td>
                <td>{{ showdate(range_data.highdate) }}</td>
              </tr>
              <tr>
                <th scope="row">Lowest:</th>
                <!-- <td name="lowest"></td> -->
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ range_data.lowprice }}</td>
                <td>{{ showdate(range_data.lowdate) }}</td>
              </tr>
            </tbody>
          </table>
          <table id="tnc" class="table is-bordered">
            <tbody>
              <th colspan="3" class="has-text-centered">Terms and Conditions</th>
              <tr>
                <th scope="row">Cash:</th>
                <td class="wide">{{ adapt_currency(selectedCurrency).symbol }}<input v-model="cash" type="number" step="1000" value="0" min="0" max="1000000"></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Assets:</th>
                <td class="wide"><input v-model="asset" type="number"  step="0.001" value="0" min="0" max="10000">BTC</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ parseFloat(range_data.fromprice * asset + cash).toFixed(2) }}</td>
              </tr>
              <tr>
                <th scope="row">Buy:</th>
                <td class="wide">
                  <input v-model="buy_asset" type="number" step="0.001" value="0" min="0" max="10000">BTC
                </td>
                <td>
                  when<input v-model="buyrate" type="number" step="0.1" value="0" min="0" max="100">% is down
                </td>
              </tr>
              <tr>
                <th scope="row">Sell:</th>
                <td class="wide">
                  <input v-model="sell_asset" type="number" step="0.001" value="0" min="0" max="10000">BTC
                </td>
                <td>
                  when<input v-model="sellrate" type="number" step="0.1" value="0" min="0" max="100">% is up
                </td>
              </tr>
            </tbody>
          </table>
          <table id="result" class="table is-bordered">
            <tbody>
              <th colspan="3" class="has-text-centered">Result</th>
              <tr>
                <th scope="row">Final Value:</th>
                <td>{{ result_rate }} %</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ result_total_value }}</td>
              </tr>
              <tr>
                <th scope="row">No Trade:</th>
                <td>{{ no_trade_rate }} %</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ no_trade_value }}</td>
              </tr>
              <tr>
                <th scope="row">Diff:</th>
                <td>{{ (result_rate - no_trade_rate).toFixed(3) }} %</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (result_total_value - no_trade_value).toFixed(2) }}</td>
              </tr>
              <tr>
                <th scope="row">Buy:</th>
                <td>{{ pred_point.filter(data => data.z == "BUY").length }}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Sell:</th>
                <td>{{ pred_point.filter(data => data.z == "SELL").length }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button class="button is-dark is-rounded" type="button" v-on:click="show_trade_table">Show/Hide Trade Points</button>
    <div class="trade_table hidden">
      <div class="columns is-desktop row">
        <div class="column col-lg-6">
          <table id="result_trade" class="table is-bordered is-hoverable" v-if="trade_table.data">
            <thead>
              <th colspan="6" class="has-text-centered">Result</th>
              <tr>
                <th class="has-text-centered" v-for="(label, key) in trade_table.header" :key="key">{{ label }}</th>
              </tr>
            </thead>
            <tbody class="is-size-7">
              <!-- <tr v-for="item in trade_table" :key="item.x">
                <td v-for="(label, key) in header" :key="'td'+key+item.x">{{item[key]}}</td>
              </tr> -->
              <tr v-for="(item, index) in trade_table.data" :key="index">
                <td>{{ showdate(item.x) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.y).toFixed(2) }}</td>
                <td :class="table_color(item.z)">{{item.z}}</td>
                <td>{{ (item.asset).toFixed(3) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.cash).toFixed(2) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.y * item.asset + item.cash).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="column col-lg-6">
          <table id="pred_trade" class="table is-bordered is-hoverable" v-if="pred_point">
            <thead>
              <th colspan="6" class="has-text-centered">Prediction</th>
              <tr>
                <th class="has-text-centered" v-for="(label, key) in trade_table.header" :key="key">{{ label }}</th>
              </tr>
            </thead>
            <tbody class="is-size-7">
              <tr v-for="(item, index) in pred_point" :key="index">
                <td>{{ showdate(item.x) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.y).toFixed(2) }}</td>
                <td :class="table_color(item.z)">{{item.z}}</td>
                <td>{{ (item.asset).toFixed(3) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.cash).toFixed(2) }}</td>
                <td>{{ adapt_currency(selectedCurrency).symbol }} {{ (item.y * item.asset + item.cash).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <label>Tel [必須]</label>
        <input v-model="userInfo.tel" type="text" class="form-control" placeholder="例) 0311112222">
        <span v-show="!validateTel" class="text-warning">Telは数値で入力してください</span> -->
      </div>
    </div>
    <div class="column hist-chart col-lg-8">
      <highcharts :constructor-type="'stockChart'" :options="predchartOptions" />
    </div>
  </div>
</template>

<script>
// import { axios } from "@/common/api.service.js";
import axios from "axios";
import HighCharts from 'highcharts';
import StockCharts from 'highcharts/modules/stock';
import $ from 'jquery';
import { ref } from "vue";
import indicators from 'highcharts/indicators/indicators';
import psar from 'highcharts/indicators/psar.js';
import rsi from 'highcharts/indicators/rsi.js';
// import exporting from 'highcharts/modules/exporting';
// import exportData from 'highcharts/modules/export-data';
// import offline from 'highcharts/modules/offline-exporting';
// import { ref } from 'vue';

StockCharts(HighCharts);
indicators(HighCharts);
psar(HighCharts);
rsi(HighCharts);
// exporting(HighCharts);
// exportData(HighCharts);
// offline(HighCharts);

HighCharts.setOptions({
    lang:{
        rangeSelectorZoom: 'Period'
    },
});

var ret_global = []
var trade_point = ref([])
var fromprice = ref(0)
var fromtime = ref(0)
var toprice = ref(0)
var totime = ref(0)
var pricechange = ref(0)
var volatility = ref(0)
var highprice = ref(0)
var highdate = ref(0)
var lowprice = ref(0)
var lowdate = ref(0)
var cash = ref(0)
var asset = ref(0)
var buy_asset = ref(0)
var buyrate = ref(0)
var sell_asset = ref(0)
var sellrate = ref(0)
var result_rate = ref(0)
var start_total_value = ref(0)
var result_total_value = ref(0)
var no_trade_rate = ref(0)
var no_trade_value = ref(0)
var pred_point = ref([])
var pred_price = ref([])
var pred_value = ref([])
var selectedCoin = ref('BTC')
var selectedCurrency = ref('USD')
var title_pair = ref("")
var optionCoins = [ 
        { id: 1, name: 'BTC' }, 
        { id: 2, name: 'ETH' }, 
        { id: 3, name: 'XRP' },
        { id: 4, name: 'XLM' },
        { id: 5, name: 'BCH' },
        { id: 6, name: 'BNB' } 
    ]
var optionCurrency = [ 
        { id: 1, name: 'USD', symbol: '$', decimal: 2}, 
        { id: 2, name: 'EUR', symbol: '€', decimal: 2}, 
        { id: 3, name: 'JPY', symbol: '¥', decimal: 0} 
    ]


function avoid_blank() {
  let inputs = [cash, asset, buy_asset, sell_asset, buyrate, sellrate]
  for (let i of inputs) {
    if (i.value == ""){
      i.value = 0
    } else if (i.value < 0){
      i.value = 0
    }
  }
}


function showtime (starttime, endtime, startprice, endprice, highest, lowest, xdataset, ydataset) {
  avoid_blank()
  // let date1 = new Date(starttime).toUTCString().slice(0,-7)
  // let date1 = time_convert(starttime)
  // let date2 = new Date(endtime).toUTCString().slice(0,-7)
  // let date2 = time_convert(endtime)
  fromtime.value = starttime
  totime.value = endtime
  highprice.value = highest
  lowprice.value = lowest
  let diff = endtime - starttime;
  let dd = 1000 * 60 * 60 * 24;
  let hh = 1000 * 60 * 60;
  // let mm = 1000 * 60;
  let days = Math.floor(diff / dd)
  let hours = Math.floor( (diff - (days * dd)) / hh )
  let period = days + ' Days ' + hours + ' Hours'
  // let mins = Math.floor( (diff - (days * dd + hours * hh)) / mm )
  fromprice.value = startprice[0]
  toprice.value = endprice[3]
  pricechange.value = ((toprice.value - fromprice.value)/fromprice.value * 100).toFixed(3)
  let yLen = ydataset.length
  let Vns = []
  for(let i = 0 ; i < yLen; i++) {
    // console.log(ydataset[i][2]);
    if (ydataset[i][1] == highest && starttime <= xdataset[i] && xdataset[i] <= endtime) {
      highdate.value = xdataset[i]
    } else if (ydataset[i][1] == highest) {
      highdate.value = xdataset[i]
      // highdate = time_convert(xdataset[i])
    }
    if (ydataset[i][2] == lowest && starttime <= xdataset[i] && xdataset[i] <= endtime) {
      lowdate.value = xdataset[i]
    } else if (ydataset[i][2] == lowest) {
      lowdate.value = xdataset[i]
      // lowdate = time_convert(xdataset[i])
    }
    if (i > 0 && starttime < xdataset[i] && xdataset[i] <= endtime) {
      let Vn = Math.log(parseFloat(ydataset[i][3]) / parseFloat(ydataset[i-1][3])) 
      Vns.push(Vn)
    }
  }
  // console.log(Vns.length)
  let Vn_avg = Vns.reduce((previous, current) =>
      previous + current  // 要素をすべて足す
  ) / Vns.length;  // 平均を求める
  let standardDeviation = Math.sqrt(  // 分散の平方根を求める
      Vns.map((current) => {  // 各要素について
          let difference = current - Vn_avg;  // 平均値との差を求める
          return difference ** 2;  // 差を2乘する
      })
      .reduce((previous, current) =>
          previous + current  // 差の2乗をすべて足す
      ) / Vns.length * 365 // 差の2乗の平均が分散
  ) * 100;
  // console.log(standardDeviation);  // 標準偏差
  
  // $('#report td[name="fromprice"]').text(fromprice.value);
  // $('#report td[name="date1"]').attr({ 
  //   'data-time': starttime,
  $('#report td[name="period"]').text(period);
  volatility.value = standardDeviation
 
  start_total_value.value = fromprice.value * asset.value + cash.value
  no_trade_value.value = (toprice.value * asset.value + cash.value).toFixed(2)
  no_trade_rate.value = ((no_trade_value.value - start_total_value.value)/start_total_value.value * 100).toFixed(3)

  // console.log(starttime, endtime)
  let buy_price = fromprice.value * (1 - (buyrate.value/100))
  let sell_price = fromprice.value * (1 + (sellrate.value/100))
  let pre_cash = cash.value
  let pre_asset = asset.value
  let pre_trade_point = []
  pre_trade_point.push({x:starttime, y:fromprice.value, z:"Start", asset:pre_asset, cash:pre_cash})
  for (let i = 0 ; i < ret_global.length; i++) {
    if (starttime <= ret_global[i][0] && ret_global[i][0] <= endtime) {
      if (ret_global[i][4] <= ret_global[i][1]){
        if (sellrate.value > 0 && sell_asset.value > 0 && ret_global[i][2] >= sell_price) {
          if (pre_asset !== 0 && pre_asset >= sell_asset.value) {
            // console.log("SELL", new Date(ret_global[i][0]).toUTCString(), sell_price)
            pre_asset -= sell_asset.value
            pre_cash += (sell_asset.value * sell_price)
            pre_trade_point.push({x:ret_global[i][0], y:sell_price, z:"SELL", asset:pre_asset, cash:pre_cash})
            buy_price = sell_price * (1 - (buyrate.value/100))
            sell_price = sell_price * (1 + (sellrate.value/100))
            
          }
        }
        else if (buyrate.value > 0 && buy_asset.value > 0 && ret_global[i][3] <= buy_price) {
          if (pre_cash !== 0 && pre_cash >= buy_price*buy_asset.value) {
            // console.log("BUY", new Date(ret_global[i][0]).toUTCString(), buy_price)
            pre_asset += buy_asset.value
            pre_cash -= (buy_price*buy_asset.value)
            pre_trade_point.push({x:ret_global[i][0], y:buy_price, z:"BUY", asset:pre_asset, cash:pre_cash})
            sell_price = buy_price * (1 + (sellrate.value/100))
            buy_price = buy_price * (1 - (buyrate.value/100))
          }
        }
      }
      else if (ret_global[i][4] > ret_global[i][1]){
        if (buyrate.value > 0 && buy_asset.value > 0 && ret_global[i][3] <= buy_price) {
          if (pre_cash !== 0 && pre_cash >= buy_price*buy_asset.value) {
            // console.log("BUY", new Date(ret_global[i][0]).toUTCString(), buy_price)
            pre_asset += buy_asset.value
            pre_cash -= (buy_price*buy_asset.value)
            pre_trade_point.push({x:ret_global[i][0], y:buy_price, z:"BUY", asset:pre_asset, cash:pre_cash})
            sell_price = buy_price * (1 + (sellrate.value/100))
            buy_price = buy_price * (1 - (buyrate.value/100))
          }
        }
        else if (sellrate.value > 0 && sell_asset.value > 0 && ret_global[i][2] >= sell_price) {
          if (pre_asset !== 0 && pre_asset >= sell_asset.value) {
            // console.log("SELL", new Date(ret_global[i][0]).toUTCString(), sell_price)
            pre_asset -= sell_asset.value
            pre_cash += (sell_asset.value * sell_price)
            pre_trade_point.push({x:ret_global[i][0], y:sell_price, z:"SELL", asset:pre_asset, cash:pre_cash})
            buy_price = sell_price * (1 - (buyrate.value/100))
            sell_price = sell_price * (1 + (sellrate.value/100))
            
          }
        }
      }
    }
  }
  pre_trade_point.push({x:endtime, y:toprice.value, z:"End", asset:pre_asset, cash:pre_cash})

  result_total_value.value = (toprice.value * pre_asset + pre_cash).toFixed(2)
  // console.log(result_total_value)
  result_rate.value = ((result_total_value.value - start_total_value.value)/start_total_value.value * 100).toFixed(3)

  trade_point.value = pre_trade_point
  return pre_trade_point
}


function tableMaker() {
    avoid_blank()
    start_total_value.value = (fromprice.value * asset.value + cash.value).toFixed(3)
    no_trade_value.value = (toprice.value * asset.value + cash.value).toFixed(3)
    no_trade_rate.value = ((no_trade_value.value - start_total_value.value)/start_total_value.value * 100).toFixed(3)

    let buy_price = fromprice.value * (1 - (buyrate.value/100))
    let sell_price = fromprice.value * (1 + (sellrate.value/100))
    let pre_cash = cash.value
    let pre_asset = asset.value
    let pre_trade_point = []
    pre_trade_point.push({x:fromtime.value, y:fromprice.value, z:"Start", asset:pre_asset, cash:pre_cash})
    for (let i = 0 ; i < ret_global.length; i++) {
      if (fromtime.value <= ret_global[i][0] && ret_global[i][0] <= totime.value) {
        if (ret_global[i][4] <= ret_global[i][1]){
          if (sellrate.value > 0 && sell_asset.value > 0 && ret_global[i][2] >= sell_price) {
            if (pre_asset !== 0 && pre_asset >= sell_asset.value) {
              // console.log("SELL", new Date(ret_global[i][0]).toUTCString(), sell_price)
              pre_asset -= sell_asset.value
              pre_cash += (sell_asset.value * sell_price)
              pre_trade_point.push({x:ret_global[i][0], y:sell_price, z:"SELL", asset:pre_asset, cash:pre_cash})
              buy_price = sell_price * (1 - (buyrate.value/100))
              sell_price = sell_price * (1 + (sellrate.value/100))
              
            }
          }
          else if (buyrate.value > 0 && buy_asset.value > 0 && ret_global[i][3] <= buy_price) {
            if (pre_cash !== 0 && pre_cash >= buy_price*buy_asset.value) {
              // console.log("BUY", new Date(ret_global[i][0]).toUTCString(), buy_price)
              pre_asset += buy_asset.value
              pre_cash -= (buy_price*buy_asset.value)
              pre_trade_point.push({x:ret_global[i][0], y:buy_price, z:"BUY", asset:pre_asset, cash:pre_cash})
              sell_price = buy_price * (1 + (sellrate.value/100))
              buy_price = buy_price * (1 - (buyrate.value/100))
            }
          }
        }
        else if (ret_global[i][4] > ret_global[i][1]){
          if (buyrate.value > 0 && buy_asset.value > 0 && ret_global[i][3] <= buy_price) {
            if (pre_cash !== 0 && pre_cash >= buy_price*buy_asset.value) {
              // console.log("BUY", new Date(ret_global[i][0]).toUTCString(), buy_price)
              pre_asset += buy_asset.value
              pre_cash -= (buy_price*buy_asset.value)
              pre_trade_point.push({x:ret_global[i][0], y:buy_price, z:"BUY", asset:pre_asset, cash:pre_cash})
              sell_price = buy_price * (1 + (sellrate.value/100))
              buy_price = buy_price * (1 - (buyrate.value/100))
            }
          }
          else if (sellrate.value > 0 && sell_asset.value > 0 && ret_global[i][2] >= sell_price) {
            if (pre_asset !== 0 && pre_asset >= sell_asset.value) {
              // console.log("SELL", new Date(ret_global[i][0]).toUTCString(), sell_price)
              pre_asset -= sell_asset.value
              pre_cash += (sell_asset.value * sell_price)
              pre_trade_point.push({x:ret_global[i][0], y:sell_price, z:"SELL", asset:pre_asset, cash:pre_cash})
              buy_price = sell_price * (1 - (buyrate.value/100))
              sell_price = sell_price * (1 + (sellrate.value/100))
              
            }
          }
        }
      }
    }
    pre_trade_point.push({x:totime.value, y:toprice.value, z:"End", asset:pre_asset, cash:pre_cash})
    result_total_value.value = (toprice.value * pre_asset + pre_cash).toFixed(3)
    // console.log(result_total_value)
    result_rate.value = ((result_total_value.value - start_total_value.value)/start_total_value.value * 100).toFixed(3)

    trade_point.value = pre_trade_point
    return pre_trade_point
}

function pricePrediction() {
  let pre_time = ret_global.slice(-1)[0][0]
  let pre_open = ret_global.slice(-1)[0][1]
  let pre_high = ret_global.slice(-1)[0][2]
  let pre_low = ret_global.slice(-1)[0][3]
  let pre_close = ret_global.slice(-1)[0][4]

  let pred_price_data = []
  for (let i = 0 ; i < ret_global.length; i++) {
    if (ret_global[i][0] >= fromtime.value && ret_global[i][0] <= totime.value){
      if (i == 0) {
        pred_price_data.push([pre_time, pre_open, pre_high, pre_low, pre_close])
      } else {
        pre_open = pre_close + pre_close * ((ret_global[i][1] - ret_global[i-1][4])/ret_global[i-1][4])    
        pre_high = pre_close + pre_close * ((ret_global[i][2] - ret_global[i-1][4])/ret_global[i-1][4])
        pre_low = pre_close + pre_close * ((ret_global[i][3] - ret_global[i-1][4])/ret_global[i-1][4])
        pre_close = pre_close + pre_close * ((ret_global[i][4] - ret_global[i-1][4])/ret_global[i-1][4])

        pred_price_data.push([pre_time, parseFloat((pre_open).toFixed(2)), parseFloat((pre_high).toFixed(2)), parseFloat((pre_low).toFixed(2)), parseFloat((pre_close).toFixed(2))])
        pre_time += (1000*60*60)
      }
    }
  }

  return pred_price_data
}

function prediction(trade_data) {
  let pred_data = []
  let pred_cash = []
  let pre_cash = cash.value
  let pre_asset = asset.value
  let buy_price = ret_global.slice(-1)[0][1] * (1 - (buyrate.value/100))
  let sell_price = ret_global.slice(-1)[0][1] * (1 + (sellrate.value/100))
  let pred_time = ret_global.slice(-1)[0][0]
  for (let i = 0 ; i < trade_data.length; i++) {
    if (i == 0) {
      pred_data.push({x:pred_time, y:ret_global.slice(-1)[0][1], z:trade_data[i].z, asset:pre_asset, cash:pre_cash})
      pred_cash.push({x:pred_time, y: (ret_global.slice(-1)[0][1]*pre_asset + pre_cash)})
    } else {
      let time_gap = trade_data[i].x - trade_data[i - 1].x
      if (trade_data[i].z == "BUY") {
        pre_asset += buy_asset.value
        pre_cash -= (buy_price*buy_asset.value)
        pred_time += time_gap
        pred_data.push({x:pred_time, y:buy_price, z:trade_data[i].z, asset:pre_asset, cash:pre_cash})
        pred_cash.push({x:pred_time, y: (buy_price*pre_asset + pre_cash)})
        sell_price = buy_price * (1 + (sellrate.value/100))
        buy_price = buy_price * (1 - (buyrate.value/100))
      } else if (trade_data[i].z == "SELL") {
        pre_asset -= sell_asset.value
        pre_cash += (sell_asset.value * sell_price)
        pred_time += time_gap
        pred_data.push({x:pred_time, y:sell_price, z:trade_data[i].z, asset:pre_asset, cash:pre_cash})
        pred_cash.push({x:pred_time, y: (sell_price*pre_asset + pre_cash)})
        buy_price = sell_price * (1 - (buyrate.value/100))
        sell_price = sell_price * (1 + (sellrate.value/100))
      } else if (trade_data[i].z == "End") {
        pred_time += time_gap
        let pricediff = (trade_data[i].y - trade_data[i-1].y) / trade_data[i-1].y
        // console.log(pricediff)
        let price = pred_data.slice(-1)[0].y * (1 + pricediff)
        pred_data.push({x:pred_time, y:price, z:trade_data[i].z, asset:pre_asset, cash:pre_cash})
        pred_cash.push({x:pred_time, y: (price*pre_asset + pre_cash)})
      }
    }
  }

  pred_value.value = pred_cash
  let pred_price_data = pricePrediction()
  // console.log(pred_data, pred_price_data)
  return {pred_data:pred_data, pred_price_data:pred_price_data}
}

function apidataset(response) {
    let ret = []
    let vol = []
    let vola_ratio = []
    response.data.Data.Data.forEach(el => {
        let tr = []
        let tr1 = parseFloat(el.high) - parseFloat(el.low) /*当日の高値-当日の安値*/
        tr.push(tr1)
        if (ret.length === 0) {
          let tr2 = 0
          let tr3 = 0
          tr.push(tr2, tr3)
        } else {
          let tr2 = Math.abs( parseFloat(el.high) - ret.slice(-1)[0].close ) /*当日の高値-前日の終値*/
          let tr3 = Math.abs( ret.slice(-1)[0].close - parseFloat(el.low) ) /*前日の終値-当日の安値*/
          tr.push(tr2, tr3)
        } 
        tr.sort((x, y) => y - x);
        // console.log(tr[0])
        /*TP（ティピカル・プライス）TPは高値、安値、終値の3つの平均値です。*/
        let tp = (parseFloat(el.high) + parseFloat(el.low) + parseFloat(el.close)) / 3
        let vola = tr[0] / tp * 100
        vola_ratio.push([ parseInt(el.time*1000),vola ])

        ret.push([ parseInt(el.time*1000),parseFloat(el.open),parseFloat(el.high),parseFloat(el.low),parseFloat(el.close) ])
        vol.push([ parseInt(el.time*1000),parseFloat(el.volumefrom) ])
    })
    return { ret: ret, vol: vol, vola_ratio: vola_ratio}
}


export default {
  name: "HelloWorld",
  // components: {
  //   VueHighcharts,
  // },
  props: {
    msg: String,
  },
  data() {
    return {
      cash: cash,
      asset: asset,
      buy_asset: buy_asset,
      buyrate: buyrate,
      sell_asset: sell_asset,
      sellrate: sellrate,
      result_rate: result_rate,
      start_total_value: start_total_value,
      result_total_value: result_total_value,
      no_trade_rate: no_trade_rate,
      no_trade_value: no_trade_value,
      pred_point: pred_point,
      trade_table: {
        header: {x:'Time', y:'Price', z:'Action', asset:'Asset', cash:'Cash', total:'Total'},
        data: trade_point
      },
      range_data: {
        fromprice: fromprice,
        fromtime: fromtime,
        toprice: toprice,
        totime: totime,
        pricechange: pricechange,
        volatility: volatility,
        highprice: highprice,
        highdate: highdate,
        lowprice: lowprice,
        lowdate: lowdate,
      },
      selectedCoin: selectedCoin,
      optionCoins: optionCoins, 
      selectedCurrency: selectedCurrency,
      optionCurrency: optionCurrency,
      chartOptions: {
          chart: {
            // backgroundColor: 'transparent',
            backgroundColor: '#232323',
            height: 650,
            // margin: [50, 55, 35, 30],
            events: {
              render: function(e) {
                window.onload = function() {
                  document.getElementById('report').addEventListener( 'change', () => {
                    let tm_data = tableMaker()
                    // console.log('e', e)
                    e.target.series[3].setData(tm_data);
                    let pred_sho = prediction(tm_data)
                    pred_point.value = pred_sho.pred_data
                    pred_price.value = pred_sho.pred_price_data
                  });
                  var th_width = $('#rep_th').width()
                  // console.log(th_width)
                  var fontSize = Math.max(14, th_width / 11);
                  $('#report th').css('font-size', fontSize);
                };
              },
              redraw: function(e) {
                // console.log('e', e)
                let Xstart = e.target.series[0].processedXData[0]
                let Xend = e.target.series[0].processedXData.slice(-1)[0]
                let Ystart = e.target.series[0].processedYData[0]
                let Yend = e.target.series[0].processedYData.slice(-1)[0]
                let xdataset = e.target.series[0].xData
                let ydataset = e.target.series[0].yData
                // console.log(Xstart, Ystart)

                if (Xstart) {
                  showtime (Xstart, Xend, Ystart, Yend, e.target.series[0].dataMax, e.target.series[0].dataMin, xdataset, ydataset);
                }

              }
            }
          },
          rangeSelector: {
            verticalAlign: 'bottom',
            // selected: 5,
            dropdown: "never",
            inputPosition: {
              align: "left",
              x: 0,
              y: 0
            },
            // inputDateFormat: '%Y/%m/%d %H',
            // inputEditDateFormat: '%Y/%m/%d %H',
            buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
                title: 'View 1 day'
            }, {
                type: 'day',
                count: 3,
                text: '3d',
                title: 'View 3 days'
            },{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 2,
                text: '2w',
                title: 'View 2 week'
            },{
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'all',
                text: 'All',
                title: 'View all'
            }],
          },
          title: { 
            // text: title_pair,
            // style: {
            //     color: '#ffffff',
            //     font: 'bold 1.5rem sans-serif'
            // }
          },
          plotOptions: {
            candlestick: {
                // color: 'pink',
                // lineColor: 'red',
                // upColor: 'lightgreen',
                // upLineColor: 'green',
                color:'#ff7f7f',
                lineColor: '#f83036',
                // color: {
                //     linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
                //     stops: [
                //         [0.1, '#f83036'], // start
                //         [0.5, '#ffffff'], // middle
                //         [0.9, '#f83036'] // end
                //     ]
                // },
                upColor: '#c9ffc9',
                upLineColor: '#47f647',
                lineWidth:1
                // upColor: {
                //     linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
                //     stops: [
                //         [0.1, '#47f647'], // start
                //         [0.5, '#ffffff'], // middle
                //         [0.9, '#47f647'] // end
                //     ]
                // },
            },
            line: {
              marker: {
                symbol: 'circle'
              }
            }
          },
          tooltip: {
              // xDateFormat: '%Y-%m-%d',
              // shared: true,
              // formatter: function (tooltip) {
              //   const header = `<span style="color: blue;">${new Date(this.x).toUTCString()}</span><br/>`
                
              //   return header + (tooltip.bodyFormatter(this.points).join(''))
              // }
          },
          accessibility: {
            enabled: false
          },
          time: {
            useUTC: true
          },
          series: [{
              showInLegend:true,
              type: 'candlestick',
              name: 'Price',
              id: 'price',
              data: [],
              groupPadding: 0.13,
              pointPadding: 0.05,
              tooltip: {
                // valueDecimals: 2,
                // valuePrefix: '$',
              },
              // dataGrouping: {
              //   enabled: false
              // }
            }, {
                showInLegend:true,
                type: 'column',
                name: 'Volume',
                // color: '#2caffe',
                data: [],
                tooltip: {
                  valueDecimals: 2,
                },
                // dataGrouping: {
                //   enabled: false
                // },
                yAxis: 1,
            }, {
                type: 'line',
                name: 'Volatility Ratio',
                data: [],
                color: '#5948ff',
                tooltip: {
                  valueDecimals: 3,
                },
                yAxis: 2,
            }, {
                type: 'line',
                name: 'Trade Point',
                color: 'orange',
                lineWidth: 5,
                opacity: 0.85,
                data: [],
                dataGrouping: {
                  enabled: false
                },
                tooltip: {
                  valueDecimals: 4,
                  // pointFormat: '{series.name}:<br><b>{point.z}</b><br/><p>{point.y}</p>',
                  pointFormatter: function(){
                    // let header = `<span style="color: blue;">${new Date(this.x).toUTCString()}</span><br/>`
                    let price = (this.y).toFixed(3)
                    if (this.z == "BUY") {
                      let body = this.series.name + ':<br><b style="color: lightgreen;">' + this.z + '</b><br/><p>' + price + '</p>'
                      return body
                    } else if (this.z == "SELL"){
                      let body = this.series.name + ':<br><b style="color: red;">' + this.z + '</b><br/><p>' + price + '</p>'
                      return body
                    } else {
                      let body = this.series.name + ':<br><b style="color: #000;">' + this.z + '</b><br/><p>' + price + '</p>'
                      return body
                    }
                    
                  },
                  shared: true
                },
                yAxis: 0,
                zIndex: 4,
                marker: {
                  enabled: true,
                  fillColor: '#ffffff',
                  lineWidth: 3,
                  lineColor: 'orange', // inherit from series
                  radius: 7,
                  // symbol: circle
                }
            },
            {
              type: 'ema',
              name: 'EMA',
              showInLegend:true,
              linkedTo: 'price',
              zIndex: 1,
              lineWidth: 2,
              color: 'purple',
              params: {
                period: 24*7
              },
              marker: {
                enabled: false
              },
              tooltip: {
                valueDecimals: 3,
              },
              enableMouseTracking: false,
            },
            {
              type: 'rsi',
              name: 'RSI',
              showInLegend:true,
              linkedTo: 'price',
              zIndex: 0,
              // lineWidth: 2,
              color: 'skyblue',
              yAxis: 3,
            }
            // {
            //   type: 'psar',
            //   name: 'PSAR',
            //   showInLegend:true,
            //   linkedTo: 'price',
            //   color: 'gray',
            //   zIndex: 1,
            //   tooltip: {
            //     valueDecimals: 3,
            //   },
            //   enableMouseTracking: false,
            // }
          ],
          navigator: {
            height: 120,
            maskFill: "rgba(255,255,255,0.15)",
            series: {
              fillOpacity: 0.2
            },
          },
          xAxis: {
            labels:{
              style: {
                  color: "white"
                }
            },
            crosshair: {
              width: 3,
              color: '#cccccc'
            },
            events: {
              setExtremes: function() {
                // console.log("e", e);
                // console.log("e.min, e.max", e.min, e.max)
              },
              afterSetExtremes: function () {
                // console.log("e", e);
                // console.log(e.min, e.max)
                // console.log(e.dataMin, e.dataMax)
                let Xstart = this.series[0].processedXData[0]
                let Xend = this.series[0].processedXData.slice(-1)[0]
                let Ystart = this.series[0].processedYData[0]
                let Yend = this.series[0].processedYData.slice(-1)[0]
                let xdataset = this.series[0].xData
                let ydataset = this.series[0].yData

                if (Xstart) {
                  let sho = showtime (Xstart, Xend, Ystart, Yend, this.series[0].dataMax, this.series[0].dataMin, xdataset, ydataset);
                  this.series[3].setData(sho);
                  let pred_sho = prediction(sho)
                  pred_point.value = pred_sho.pred_data
                  pred_price.value = pred_sho.pred_price_data
                }
              }
            }
          },
          yAxis: [{
            // plotBands: {
            //     color: 'rgba(169, 255, 101, 0.4)',
            //     from: 41500.94,
            //     to: 42000.57,
            //     zIndex: 3,
            //     label: {
            //         text: 'Resistance Zone'
            //     }
            // },
            crosshair: {
              width: 1,
              color: '#cccccc'
            },
            gridLineWidth: 0.5,
            gridLineColor:"gray",
            labels: {
                align: 'left',
                x: 5,
                style: {
                  color: "white"
                }
            },
            title: {
                text: 'PRICE',
                x: -25,
                y: -125,
                style: {
                  color: "white"
                }
            },
            height: '80%',
            lineWidth: 1,
            resize: {
                enabled: true
            }
          }, {
            labels: {
                align: 'right',
                x: -3
            },
            title: {
                text: 'VOLUME',
                x: 0,
                y: 40,
                style: {
                  color: "#2caffe"
                }
            },
            gridLineWidth: 0,
            top: '70%',
            height: '30%',
            offset: 0,
            lineWidth: 1
          }, {
            opposite:false,
            labels: {
                align: 'left',
                x: 3
            },
            title: {
                text: 'Volatility Ratio',
                x: 0,
                y: 40,
                style: {
                  color: "#5948ff"
                }
            },
            gridLineWidth: 0,
            top: '40%',
            height: '50%',
            offset: 0,
            lineWidth: 0
          }, {
            opposite:false,
            labels: {
                align: 'left',
                x: 3
            },
            title: {
                text: 'Trade Point',
                x: 0,
                y: -30,
                style: {
                  color: "orange"
                }
            },
            gridLineWidth: 0,
            // top: '40%',
            // height: '50%',
            offset: 0,
            lineWidth: 1
          }],
          // exporting: {
          //   // showTable: true,
          //   buttons: {
          //     // contextButton: {
          //     //     menuItems: ['downloadPDF', 'downloadCSV', 'viewData']
          //     // },
          //     contextButton: {
          //       menuItems: null,
          //       onclick: function() {
          //         if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
          //           this.dataTableDiv.style.display = 'none';
          //         } else {
          //           console.log(this);
          //           this.viewData();
          //           // this.dataTableDiv.style.display = '';
          //         }
          //       }
          //     }
          //   }
          // },
          legend: {
              enabled: true,
              itemStyle: {"color": "white"},
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'top'
          },
          // responsive: {
          //   rules: [{
          //     condition: {
          //       maxWidth: 800
          //     },
          //     chartOptions: {
          //       rangeSelector: {
          //         inputEnabled: false
          //       }
          //     }
          //   }]
          // }
        },
      predchartOptions: {
        chart: {
          backgroundColor: 'navy',
          height: 400,
          // width:500
          margin: [50, 55, 35, 30]
        },
        title: { 
          text: 'Your Blueprint',
          style: {
              color: '#ffffff',
              font: 'bold 1.5rem sans-serif'
          },
        },
        accessibility: {
          enabled: false
        },
        time: {
          useUTC: true
        },
        rangeSelector: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        plotOptions: {
            candlestick: {
                color:'#ff7f7f',
                lineColor: '#f83036',
                upColor: '#c9ffc9',
                upLineColor: '#47f647',
                lineWidth:1
            },
            line: {
              marker: {
                symbol: 'circle'
              }
            }
        },
        series: [{
          type: 'candlestick',
          name: 'Price',
          data: pred_price,
          groupPadding: 0.13,
          pointPadding: 0.05,
          yAxis: 0,
        },{
          type: 'line',
          name: 'Trade Point',
          color: 'orange',
          lineWidth: 5,
          opacity: 0.85,
          data: pred_point,
          dataGrouping: {
            enabled: false
          },
          tooltip: {
            valueDecimals: 4,
            pointFormatter: function(){
              let price = (this.y).toFixed(3)
              if (this.z == "BUY") {
                let body = this.series.name + ':<br><b style="color: lightgreen;">' + this.z + '</b><br/><p>' + price + '</p>'
                return body
              } else if (this.z == "SELL"){
                let body = this.series.name + ':<br><b style="color: red;">' + this.z + '</b><br/><p>' + price + '</p>'
                return body
              } else {
                let body = this.series.name + ':<br><b style="color: #000;">' + this.z + '</b><br/><p>' + price + '</p>'
                return body
              }
            },
            shared: true
          },
          yAxis: 0,
          zIndex: 1,
          marker: {
            enabled: true,
            fillColor: '#ffffff',
            lineWidth: 3,
            lineColor: 'orange', // inherit from series
            radius: 7,
            // symbol: circle
          }
        },{
          type: 'line',
          name: 'Total Value',
          color: 'red',
          lineWidth: 2,
          compare: 'percent',
          opacity: 0.85,
          data: pred_value,
          yAxis: 1,
          dataGrouping: {
            enabled: false
          },
          tooltip: {
            valueDecimals: 2,
            valuePrefix: '$',
          },
        }],
        xAxis: {
          lineColor: "#a9a9a9",
          labels:{
            style: {
                color: "white"
              }
          },
          crosshair: {
            width: 3,
            color: '#cccccc'
          },
        },
        yAxis: [{
            lineColor: "#a9a9a9",
            crosshair: {
              width: 1,
              color: '#cccccc'
            },
            gridLineWidth: 0.5,
            gridLineColor:"#cdcdcd",
            labels: {
                align: 'left',
                x: 10,
                style: {
                  color: "white"
                }
            },
            title: {
                text: 'PRICE',
                x: -10,
                y: 25,
                style: {
                  color: "white"
                }
            },
            height: '100%',
            lineWidth: 1,
            resize: {
                enabled: true
            }
          },{
            lineColor: "#a9a9a9",
            opposite:false,
            labels: {
                align: 'left',
                x: 0,
                style: {
                  color: "white"
                },
                // format: '{value:.2f}%'
                format: '{value}%'
            },
            title: {
                align: 'middle',
                text: 'Total Value',
                x: 0,
                y: 20,
                style: {
                  color: "red"
                }
            },
            gridLineWidth: 0,
            top: '0%',
            height: '100%',
            offset: 0,
            lineWidth: 1
          },]
      }
    };
  },
  computed: {
      // start_total_value: function() {
      //   let fromprice = ref(parseFloat($('#report td[name="fromprice"]').text()));
      //   return this.cash_val + this.asset_val * fromprice.value
      // },
      // validation: function () {
      //   return (this.validateTel);
      // },
      // validateTel: function () {
      //   var pattern = /^\d+$/; // 1文字以上の整数のみ許容
      //   return pattern.test(this.userInfo.tel.trim());
      // },
  },
  watch: {
    // "userInfo.tel": function(newVal, oldVal) {
    //   // データの値が変化した時にコンソールに新しい値と古い値を出力
    //   console.log(newVal, oldVal);
    //   console.log("this.chartOptions", this.chartOptions.series[3])
    //   this.chartOptions.series[3].data[1][1] = 40000
    // },
    // "trade_table.data": {
    //   handler(newValue, oldValue) {
    //     console.log(newValue, oldValue)
    //   },
    //   deep: true
    // },
  },
  methods: {
    show_trade_table: function() {
      let element = document.querySelector('.trade_table')
      element.classList.toggle('hidden');
    },
    showdate: function(unixtime) {
      let predate = new Date(unixtime)
      let editted_time = (predate.getUTCMonth() + 1) + '/' + predate.getUTCDate() + '/' + predate.getFullYear() + ' ' + predate.getUTCHours().toString().padStart(2, '0') + ':' + predate.getUTCMinutes().toString().padStart(2, '0');
      return editted_time
    },
    table_color: function(item) {
      if (item == "BUY") {
        return 'has-background-success-light has-text-success-dark'
      } else if (item == "SELL") {
        return 'has-background-danger-light has-text-danger-dark'
      }
    },
    setAPIdata: function() {
      title_pair.value = selectedCoin.value + " / " + selectedCurrency.value
      axios.get("/api/v1/rest", {
        params: {
          coin: selectedCoin.value,
          currency: selectedCurrency.value
        },
      }).then(response => {
        let apidata = apidataset(response)
        this.chartOptions.series[0].data = apidata.ret
        this.chartOptions.series[1].data = apidata.vol
        this.chartOptions.series[2].data = apidata.vola_ratio
        ret_global = apidata.ret
      })
    },
    adapt_currency: function (curr) {
      let coin = optionCurrency.find((v) => v.name == curr);
      return coin
    }
  },
  mounted() {
    axios.get("/api/v1/rest", {
      params: {
        coin: selectedCoin.value,
        currency: selectedCurrency.value
      },
      // headers: {
      //   "Content-Type": "application/json",
      //   'Access-Control-Allow-Headers': '*',
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Methods': '*'
      // }
    })
      .then(response => {
        let apidata = apidataset(response)
        this.chartOptions.series[0].data = apidata.ret
        this.chartOptions.series[1].data = apidata.vol
        this.chartOptions.series[2].data = apidata.vola_ratio
        ret_global = apidata.ret

      })
      .catch((e) => console.log(e));
  },
};


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#container {
    /* height: 300px; */
    /* max-width: 600px; */
    /* max-width: 90%; */
    background-color: var(--background-dark);
}
</style>

<style>
#report table, .trade_table table{
  font-family: sans-serif;
  font-size: 0.8em;
  /* margin: 0.5rem; */
  border: 1px #ababab solid;
}
#report th{
  width: 70px;
  font-style: bold;
  text-align: right;
  font-size: 1.1vw;
  word-wrap: break-word;
}
#report input {
  width: 50px;
}
#report .wide input{
  width: 80px;
}
.hidden {
  display: none;
}
#dop th, #dop td{
  background-color: #232323;
  color: white;
  border-color: darkcyan
}
#tnc th, #tnc td{
  background-color: #232323;
  color: white;
  border-color: darkmagenta;
}
#result th, #result td, #result_trade th, #result_trade td{
  background-color: #232323;
  color: white;
  border-color: darkturquoise;
}
#pred_trade th, #pred_trade td{
  background-color: #232323;
  color: white;
  border-color: darkorange;
}

</style>
