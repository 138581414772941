import { createApp } from 'vue'
import App from './App.vue'
import Highcharts from "highcharts";
import StockModule from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import router from './router'

// In order to use Highcharts Stock we need to
// wrap Highcharts with the correct module:
StockModule(Highcharts);

// Create a Vue3 app
const app = createApp(App);

// Use the HighchartsVue plugin, register <highcharts> component
app.use(HighchartsVue);
app.use(router)

// Mount the application
app.mount("#app");
